<template>
  <div class="card flex justify-content-center">
    <Dialog v-model:visible="visible" header="Header" :style="{ width: '50vw' }">
      <template #header>
        <div class="flex">
          <span class="font-bold">
            {{ agregar === true ? 'Agregar': 'Modificar'}} # {{form.RowId}}
          </span>
          <span class=""> ( Articulo /Lote )  </span>
        </div>
      </template>
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <div class="p-fluid col-span-3">
          <label for="itemCode" class="block mb-2">Articulo</label>
          <div class="grid grid-cols-3 gap-4">
            <AutoComplete v-model="form.ItemCode"
                          :suggestions="suggestions"
                          optionLabel="ItemCode"
                          optionValue="ItemCode"
                          @item-select="selectArt"
                          @complete="search"
                          size="small"
            >
              <template #option="{option}">
                <div class="flex align-options-center">
                  <div>{{ option.Descripcion }}</div>
                </div>
              </template>
            </AutoComplete>
            <InputText v-model="form.ItemName" id="itemName" class="col-span-2" size="small" disabled />
          </div>
        </div>
        <div class="p-fluid">
          <label for="quantity" class="block mb-2">Cantidad</label>
          <InputText v-model="form.Quantity" id="quantity" size="small" />
        </div>
        <div class="p-fluid">
          <label for="numLote" class="block mb-2">Lote</label>
          <InputText v-model="form.NumLote" id="numLote" size="small" />
        </div>
        <div class="p-fluid">
          <label for="fecVen" class="block mb-2">Feccha Vencimiento</label>
          <input type="date" v-model="form.FecVen" id="fecVen" class="p-inputtext p-component  p-inputtext-sm">
        </div>
        <div class="col-span-3 p-fluid">
          <label for="justificacion" class="block mb-2">Justificacion</label>
          <Textarea v-model="form.Justificacion" id="justificacion" rows="3" cols="30" />
        </div>
      </div>
      <template #footer>
        <div class="flex justify-end">
          <Button label="Cancelar" severity="danger" @click="onCancel"/>
          <Button label="Confirmar" @click="onConfirmar"/>
        </div>
      </template>
    </Dialog>
  </div>
</template>
<script>
import { reactive, ref } from 'vue'
import { useVer } from '../../../store/ver.store'
import * as Yup from 'yup'
import { useToast } from 'primevue/usetoast'
export default {
  setup () {
    const posicionEditar = ref(null)
    const storeVer = useVer()
    const toast = useToast()
    const visible = ref(false)
    const form = reactive({
      ItemCode: null,
      ItemName: null,
      Quantity: 0,
      RowId: null,
      NumLote: null,
      FecVen: null,
      Justificacion: null,
      AgrupId: null,
      IdItemCode: null
    })
    const agregar = ref(false)
    const onVisible = ({ data, add, posicion }) => {
      onClear()
      posicionEditar.value = posicion
      agregar.value = add
      visible.value = !visible.value
      if (!add) {
        form.ItemCode = data.ItemCode
        form.ItemName = data.ItemName
        form.Quantity = data.Quantity
        form.RowId = data.RowId
        form.NumLote = data.NumLote
        form.FecVen = data.FecVen
        form.AgrupId = data.AgrupId
        form.IdItemCode = data.IdItemCode
        form.Justificacion = data.Justificacion
      }
    }
    const onCancel = () => {
      visible.value = false
    }
    const onClear = () => {
      form.ItemCode = null
      form.ItemName = null
      form.Quantity = null
      form.RowId = null
      form.NumLote = null
      form.FecVen = null
      form.AgrupId = null
      form.IdItemCode = null
    }
    const formSchema = Yup.object().shape({
      ItemCode: Yup.string().required('El articulo es requerido').label('Articulo').typeError('El articulo es requerido'),
      Quantity: Yup.number().required('La cantidad es requerida').min(1).label('Cantidad').typeError('La cantidad es requerida'),
      NumLote: Yup.string().required('El numero de lote es requerido').label('Num Lote').typeError('El numero de lote es requerido'),
      FecVen: Yup.date().required('La fecha de vencimiento es requerida').label('Fecha Vencimiento').typeError('La fecha de vencimiento es requerida'),
      Justificacion: Yup.string().label('Justificacion').typeError('La justificación es requerida')
    })
    const onConfirmar = async () => {
      try {
        await formSchema.validate(form, { abortEarly: false })
        if (agregar.value) {
          storeVer.addRow(form)
        } else {
          storeVer.modificarRow(posicionEditar.value, form)
        }
        visible.value = false
      } catch (error) {
        for (const e of error.errors) {
          toast.add({ severity: 'error', summary: e, life: 3000 })
        }
      }
    }
    const suggestions = ref([])
    const search = ({ query }) => {
      if (query.length === 0) {
        suggestions.value = []
      } else {
        const data = { query }
        if (!agregar.value) {
          data.AgrupId = form.AgrupId
        }
        storeVer.searchArticulo(data).then((res) => {
          suggestions.value = res
        })
      }
    }
    const selectArt = (e) => {
      form.ItemName = e.value ? e.value.ItemName : form.ItemName
      form.ItemCode = e.value ? e.value.ItemCode : form.ItemCode
      form.IdItemCode = e.value ? e.value.IdItemCode : form.IdItemCode
    }
    return {
      visible,
      onVisible,
      form,
      onCancel,
      onConfirmar,
      search,
      suggestions,
      selectArt,
      agregar
    }
  }
 }
</script>

<style scoped>

</style>
